import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
import ScratchCard from '../utils/ScratchCard';
import { confirmAlert } from '../utils/react-confirm-alert';
import scratchMaskImage from '../../styles/images/scratcher-top-image2.jpg';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AuthService from '../utils/AuthService';
import Confetti from 'react-confetti';
import posed, { PoseGroup } from 'react-pose';
import fileDownload from 'js-file-download';
import '../../styles/css/Scratcher.css';
import '../../styles/css/Home.css';
import PulseLoader from "react-spinners/PulseLoader";
import {validateEmail} from "../utils/HelpfulFunction";
import { Textfit } from 'react-textfit';
const Axios = require('axios');

const ScratchContainer = posed.div({
  enter: {
    y: 1000,
    opacity: 1,
    delay: 360000,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 3000 }
    }
  }
});

const Box = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
});

class Home extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.handleImageErrored = this.handleImageErrored.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleInfoAndRules = this.toggleInfoAndRules.bind(this);
        this.toggleSupport = this.toggleSupport.bind(this);
        let tempEmail = this.Auth.getProfile().sub;
        if(this.Auth.getProfile().sub.includes("sqwadapp.co")){
          tempEmail = ""
        }
        this.state = {
            scratcherList: [],
            showNext: false,
            isVisible: false,
            winner: false,
            scratchState: 0,
            loading:true,
            gameId: null,
            startConfetti: false,
            value: tempEmail,
            currentScratcherLocation: 0,
            modal:false,
            downloadModal: false,
            areYouSureModal: false,
            sendPrizeInfoModal: false,
            letsPlayDisabled: false,
            vwArray: ['vw12', 'etsu', 'towson', 'loyolamaryland', 'miamiredhawks', 'furman', 'wm', 'suu', 'samford', 'fiu']
        };
    }

    handleImageLoaded() {
      this.setState({
        loading: false,
        isVisible: true,
      });
    }

    handleImageErrored() {
      console.log("IMAGE FAILED TO LOAD");
      this.setState({ loading: false });
    }

    wrapText(context, text, x, y, maxWidth, lineHeight) {
        const words = text.split(' ');
        let line = '';
        let count = 1;

        for(let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
                count++;
            }
            else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
        return count*lineHeight;
    }

    fitTextOnCanvas(text,fontFace,yPosition,ctx, maxWidthAllowed){

        // start with a large font size
        let fontsize=45;

        // lower the font size until the text fits the canvas
        do{
            fontsize--;
            ctx.font=fontsize+"px "+fontFace;
        }while(ctx.measureText(text).width> maxWidthAllowed - 20)

        // draw the text
        ctx.fillText(text,maxWidthAllowed/2,yPosition);
    }

    componentDidMount(){
      const subValue = this.state.value;
      if(!validateEmail(subValue)){
        this.setState({
          value: ""
        })
      }
      // var element = document.getElementById('mainContent')
      // var transfromString = ("translate(0%, 0%)");
      // element.style.width = "100%"
      // element.style.webkitTransform = transfromString;
      // element.style.MozTransform = transfromString;
      // element.style.msTransform = transfromString;
      // element.style.OTransform = transfromString;
      // element.style.transform = transfromString;
    }

    moveUpArrayLength(currentLocation){
      const newLocation = currentLocation + 1;
      this.setState({
        hideRevealButton:false,
        showNext: false,
        currentScratcherLocation: newLocation,
      })
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getScratchers(){
        this.setState({
            loading: true,
            letsPlayDisabled: true
        });
      const teamLocation = this.props.teamLocation;
      const tenantVariables = this.props.tenantVariables;
      const stringConstants = this.props.stringConstants || {};
      const vm = this;
      const domainRoot = this.Auth.domain;
      this.Auth.fetch(domainRoot + 'services/app/Game/GetCurrentGame', {
        method: 'GET',
      }).then(res => {
        if(!res){
            vm.setState({
              loading: false,
              letsPlayDisabled: false
            });
            confirmAlert({
                title: stringConstants.UNKNOWNERRORTITLE,
                message: stringConstants.SOMETHINGWENTWRONGTEXT,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables
            });
            return;
        }
        if(res.apiStatus === "game_found"){
          this.props.setTheme().then(gotTheme => {
            if(gotTheme.collectDistance && gotTheme.acceptableDistance && gotTheme.longitude && gotTheme.latitude){
              const locationPermissions = sessionStorage.getItem('locationPermissions' + teamLocation);
              if(locationPermissions === "false"){
                const locationPermissionsHeader = gotTheme.locationPermissionsHeader || "Location Permissions Required";
                const locationPermissionsBody = gotTheme.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
                vm.setState({
                    loading: false,
                    letsPlayDisabled: false
                });
                confirmAlert({
                    title: locationPermissionsHeader,
                    message: locationPermissionsBody,
                    cancelLabel: "",
                    confirmLabel: stringConstants.OKTEXT,
                    variables: tenantVariables,
                    onConfirm: () => {
                      vm.setState({
                        loading: true,
                      });
                      this.getLocationPermission(gotTheme, teamLocation, domainRoot, res, vm)
                    }
                })
              } else {
                this.getLocationPermission(gotTheme, teamLocation, domainRoot, res, vm)
              }
            } else {
              this.continueStartingGame(vm, gotTheme, res, domainRoot, true)
            }
          })
        } else if(res.message === "Too Many Requests"){
            console.log("TOO MANY REQUESTS")
            vm.setState({
                loading: false,
                letsPlayDisabled: false
            })
        } else {
            confirmAlert({
                title: stringConstants.GAMEINACTIVETEXT,
                message: stringConstants.COMEBACKLATERTEXT,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables
            });
              vm.setState({
                loading: false,
                letsPlayDisabled: false
              })
        }
      })
    }

    download() {
        if(this.state.downloadedFile){
            fileDownload(this.state.downloadedFile, "reward.jpg")
            return
        }
        this.setState({
            downloadingImage: true
        })
        const vm = this;
        this.Auth.requestToDownload(this.state.gameId).then(res => {
            this.setState({
                downloadingImage: false,
            })
            if(res && res.apiStatus === "winning_reward_downloaded"){
                Axios.get(res.entity, {
                    responseType: 'blob',
                }).then(res => {
                    fileDownload(res.data, "reward.jpg")
                    vm.setState({
                        downloadingImage:false,
                        downloadedFile: res.data
                    })
                });
                this.setState({
                    scratchState:2,
                    downloadModal:false,
                    startConfetti: false
                })
            }
        })
    }

    getLocationPermission(gotTheme, teamLocation, domainRoot, res, vm){
      const stringConstants = this.props.stringConstants || {};
      let locationErrorTitle = stringConstants.LOCATIONERRORHEADER;
      this.props.getLocation(gotTheme, stringConstants).then(locationObject => {
          const distance_meters = locationObject.distance;
          const lat = locationObject.lat;
          const long = locationObject.long;
        sessionStorage.setItem('locationPermissions' + teamLocation, "true");
        const allowed_distance = this.changeMilesToMeters(gotTheme.acceptableDistance || 100); //In miles
        if(!gotTheme.canPlayOutside && distance_meters <= allowed_distance){
          this.continueStartingGame(vm, gotTheme, res, domainRoot, true);
        } else if(gotTheme.canPlayOutside && distance_meters > allowed_distance) {
            this.continueStartingGame(vm, gotTheme, res, domainRoot, true);
        } else if(gotTheme.locationCheckFailedCanPlay === true) {
            this.continueStartingGame(vm, gotTheme, res, domainRoot, false);
        } else {
          this.setState({
            modal:false,
            loading:false,
            letsPlayDisabled: false
          });
        let locationErrorMessage = stringConstants.TOOFARFROMGAMEAREATOPART;
        if(gotTheme.canPlayOutside){
            locationErrorMessage = stringConstants.TOOCLOSETOGAMEAREATOPLAY;
        }
        if(gotTheme.notAcceptableLocationMessage){
            locationErrorMessage = gotTheme.notAcceptableLocationMessage;
        }
        let notAcceptableLocationHeader = stringConstants.LOCATIONERRORHEADER;
        if(gotTheme.notAcceptableLocationHeader){
            notAcceptableLocationHeader = gotTheme.notAcceptableLocationHeader;
        }
          confirmAlert({
              title: notAcceptableLocationHeader,
              message: locationErrorMessage,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              variables: gotTheme
          });
        }
      }, error => {
        this.setState({
            modal:false,
            loading:false,
            letsPlayDisabled: false
        });
        sessionStorage.setItem('locationPermissions' + teamLocation, "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
            title: locationErrorTitle,
            message: error,
            cancelLabel: "",
            confirmLabel: stringConstants.OKTEXT,
            variables: gotTheme
        });
      })
    }

    continueStartingGame(vm, tenantVariables, res, domainRoot, chanceToWin=true){
      const stringConstants = this.props.stringConstants || {};
      const gameObject = {"gameId": res.entity.id, "chanceToWin": chanceToWin};
        const teamLocation = this.props.teamLocation;
      vm.Auth.fetch(domainRoot + 'services/app/Game/UserStartGame', {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json',
         },
        body: JSON.stringify(gameObject)
      }).then(response => {
        if(!response.entity){
          vm.setState({
            loading: false,
            letsPlayDisabled: false
          });
          if(response.apiStatus === "user_already_played"){
            let anotherChanceToWinMessage = stringConstants.COMEBACKNEXTTIMETEXT;
            let alreadyPlayedHeader = stringConstants.ALREADYPLAYEDHEADER;
            if(teamLocation.indexOf("clevelandbrowns") !== -1){
              anotherChanceToWinMessage = "Come back daily for another chance to win."
            }
            confirmAlert({
                variables: tenantVariables,
                title: alreadyPlayedHeader,
                message: anotherChanceToWinMessage,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                onConfirm: () => {
                  console.log("confirmed")
                }
            });
            return;
          } else if(response.apiStatus === "game_has_no_prizes"){
              const rewardsOutHeader = tenantVariables.rewardsOutHeader || "Out Of Prizes!"
              const rewardsOutBody = tenantVariables.rewardsOutBody || "Sorry, we’ve run out of prizes. Come back next time to try again!"
              confirmAlert({
                  variables: tenantVariables,
                  title: rewardsOutHeader,
                  message: rewardsOutBody,
                  cancelLabel: "",
                  confirmLabel: stringConstants.OKTEXT,
                  onConfirm: () => {
                      console.log("confirmed")
                  }
              });
              return;
          } else if(response.message === "Too Many Requests"){
              vm.setState({
                  loading: false
              });
            return;
          }
          let gameClosedTitle = stringConstants.GAMECLOSEDTEXT;
          let gameClosedMessage = stringConstants.GAMENOTACTIVETEXT;
            if(teamLocation.indexOf("njdevils") !== -1){
                gameClosedMessage = "Game begins before puck on drop April 2nd at 7:05 PM ET"
            }
            if(teamLocation.indexOf("dallascowboys") !== -1){
                gameClosedTitle = "This game isn’t active right now";
                gameClosedMessage = "Come back next game day to play!";
            }
            if(teamLocation === "rays"){
                gameClosedTitle = "Come Back On Wednesday Games!";
                gameClosedMessage = "Scratch & Win goes live on Wednesday games. Come back then to play!";
            }
          confirmAlert({
              title: gameClosedTitle,
              message: gameClosedMessage,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              variables: tenantVariables
          });
          return
        }
        const gameId = response.entity.gameId;
        if(response.entity.gameStatus === 1){
          if(response.entity.playerStatus === 1){
            const winner_status = response.entity.isWinningPack;
            let userEmail = response.entity.userEmail;
            if(!userEmail || userEmail && userEmail.includes("sqwadapp.co")){
                if(this.Auth.getProfile().sub.includes("sqwadapp.co")){
                    userEmail = ""
                } else {
                    userEmail = this.Auth.getProfile().sub;
                }
            }
            response.entity.rewards.sort(function() { return 0.5 - Math.random() });
            window.location.hash ='#scratching';
            vm.setState({
                isVisible: true,
                winner: winner_status,
                value: userEmail,
                scratchState: 1,
                scratcherList: response.entity.rewards,
                gameId: gameId
            })
          } else {
            vm.setState({
                isVisible: true,
                loading: false,
                gameId: gameId,
                letsPlayDisabled: false
            });
            confirmAlert({
                title: stringConstants.ALREADYPLAYEDHEADER,
                message: stringConstants.COMEBACKNEXTTIMETEXT,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                  console.log("confirmed")
                }
            });
          }
        } else {
          vm.setState({
            loading: false,
            gameId: gameId,
            letsPlayDisabled: false
          });
          confirmAlert({
              title: stringConstants.GAMEINACTIVETEXT,
              message: stringConstants.COMEBACKLATERTEXT,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              variables: tenantVariables,
              onConfirm: () => {
                console.log("confirmed")
              }
          });
        }
      }).catch(err =>{
        vm.setState({
            loading:false,
            scratchState: 0,
            letsPlayDisabled: false,
            scratcherList: []
        });
        confirmAlert({
            title: stringConstants.UNKNOWNERRORTITLE,
            message: stringConstants.SOMETHINGWENTWRONGTEXT,
            cancelLabel: "",
            confirmLabel: stringConstants.OKTEXT,
            variables: tenantVariables,
            onConfirm: () => {
              console.log("confirmed")
            }
        });
      })
    }

    showNextArrow(){
        const teamLocation = this.props.teamLocation;
        const location = this.state.currentScratcherLocation;
        let winningTicketShown = false;
        if(this.state.scratcherList[location].isWinning && teamLocation !== "odu"){
            winningTicketShown = true
        }
        const tenantVariables = this.props.tenantVariables || {};
        const stringConstants = this.props.stringConstants || {};
        let losingHeader = tenantVariables.losingHeader;
        let losingMessage = tenantVariables.losingMessageText;
        if(losingHeader === "null" || losingHeader === "undefined" || !losingHeader){
            losingHeader = "😭 SORRY! 😭"
        }
        if(losingMessage === "null" || losingMessage === "undefined" || !losingMessage){
            losingMessage = "You didn't win anything but come back next game and try again!"
        }
        if(this.state.scratcherList.length === location + 1 || winningTicketShown){
            if(this.state.winner){
                this.setState({
                    hideRevealButton: true,
                    startConfetti: true
                }, () => {
                    const vm = this;
                    const isColumbusClippers = teamLocation === "columbusclippers";
                    if(tenantVariables.collectEmail && validateEmail(vm.state.value) && (process.env.REACT_APP_API_LINK === "https://dev-games.sqwadhq.com/api/" || vm.state.vwArray.indexOf(teamLocation) !== -1 || teamLocation === "nickdemo" || process.env.REACT_APP_API_LINK === "http://localhost:9284/api/")){
                        vm.sendWinningEmailAddress(true)
                    } else if(isColumbusClippers) {
                        vm.sendWinningEmailAddress(true)
                    } else {
                        setTimeout(function () {
                            if(tenantVariables.authRequired){
                                vm.setState({
                                    showNext:false,
                                    downloadModal:true,
                                }, () => {
                                    let modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
                                    modalBackdrop.style.display = "none";
                                    let maxWidthAllowed = 300;
                                    const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
                                    if(bodyWidth > 0 && bodyWidth < 300){
                                        maxWidthAllowed = bodyWidth;
                                    }
                                    const scratcherCard = vm.state.scratcherList[location];
                                    let c = document.createElement("canvas");
                                    let cf = document.createElement("canvas")
                                    let ctxf = cf.getContext('2d');
                                    let ctx = c.getContext('2d');
                                    let img1 = new Image();
                                    img1.onload= function() {
                                        ctx.drawImage(img1, 0, 0, maxWidthAllowed, maxWidthAllowed);
                                        ctxf.drawImage(img1, 0, 0, maxWidthAllowed, maxWidthAllowed);
                                    };
                                    img1.src = scratcherCard.rewardTemplate.emailImage;
                                    ctx.font = "25px Oswald";
                                    let textHeight = vm.wrapText(ctx, scratcherCard.rewardTemplate.description, maxWidthAllowed/2, 325, maxWidthAllowed, 25)
                                    let height = textHeight+325;
                                    if(scratcherCard.redeemCode){
                                        vm.fitTextOnCanvas(scratcherCard.redeemCode,"Oswald",textHeight+350, ctx, maxWidthAllowed);
                                        height = textHeight+375;
                                    }
                                    cf.style.backgroundColor = "white";
                                    cf.height = height;
                                    ctxf.textAlign = "center";
                                    ctxf.textBaseline = "middle";
                                    ctxf.fillStyle = "black";
                                    ctxf.font = "25px Oswald";
                                    vm.wrapText(ctxf, scratcherCard.rewardTemplate.description, maxWidthAllowed/2, 325, maxWidthAllowed, 25)
                                    if(scratcherCard.redeemCode){
                                        vm.fitTextOnCanvas(scratcherCard.redeemCode,"Oswald",textHeight+350, ctxf, maxWidthAllowed);
                                    }
                                    document.getElementById("downloadModalId").appendChild(cf);
                                })
                            } else if(vm.props.isMlbTeam) {
                                vm.sendWinningEmailAddress(true)
                            } else {
                                vm.setState({
                                    showNext:false,
                                    modal:true,
                                }, () => {
                                    let modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
                                    modalBackdrop.style.display = "none";
                                })
                            }
                        }, 3000);
                    }
                })
            } else {
                confirmAlert({
                    variables: tenantVariables,
                    title: losingHeader,
                    message: losingMessage,
                    confirmLabel: "",
                    cancelLabel: stringConstants.OKTEXT
                });
                this.setState({
                  scratchState:2,
                })
            }
        } else {
            this.setState({
                hideRevealButton:true,
                showNext:true
            })
        }
    }

    toggle() {
      var vm = this;
      vm.setState({
        modal: !vm.state.modal,
        startConfetti: false,
        areYouSureModal: !vm.state.areYouSureModal,
      });
      // confirmAlert({
      //   variables: tenantVariables,
      //   title: 'ARE YOU SURE?',
      //   message: "Once you give up your prize you can't get it back. It's gone forever!",
      //   confirmLabel: "GIVE UP PRIZE",
      //   cancelLabel: 'BACK',
      //   onConfirm: () => {
      //     if(vm.state.scratchState === 1){
      //       vm.setState({
      //         scratchState:2,
      //       });
      //     }
      //   },
      //   onCancel: () => {
      //     vm.setState({
      //       modal: true,
      //     }, () => {
      //       var modalBackdrop = document.getElementsByClassName("modal-backdrop")[0]
      //       modalBackdrop.style.display = "none";
      //     })
      //   }
      // })
    }

    areYouSureToggle(){
      const vm = this;
      if(vm.state.scratchState === 1){
        vm.setState({
            areYouSureModal: !vm.state.areYouSureModal,
            scratchState:2,
            downloadModal: false,
            startConfetti: false
        });
      } else {
        vm.setState({
          areYouSureModal: !vm.state.areYouSureModal,
          modal: !vm.state.modal,
        }, () => {
          const modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
          modalBackdrop.style.display = "none";
        });
      }
    }

    sendPrizeInfoToggle(){
      const vm = this;
      vm.setState({
        sendPrizeInfoModal: !vm.state.sendPrizeInfoModal,
        modal: !vm.state.modal
      })
    }

    toggleInfoAndRules(){
        this.setState({
            modalInfoAndRules: !this.state.modalInfoAndRules,
            modal: this.props.isMlbTeam
        });
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport
        });
    }

    handleChange(event) {
      this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        const email = this.state.value;
        const tenantVariables = this.props.tenantVariables || {};
        const stringConstants = this.props.stringConstants || {};
        const errorInputTitle = stringConstants.INPUTERRORTITLE;
        const errorEmailInputMessage = stringConstants.EMAILCANTBEBLANK;
        if(!email){
          this.setState({
            modal:false,
            startConfetti: false
          });
          confirmAlert({
              variables: tenantVariables,
              title: errorInputTitle,
              message: errorEmailInputMessage,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              onConfirm: () => {
                this.setState({
                  modal:true
                })
              }
          });
          return;
        }
        if(!validateEmail(email)){
          this.setState({
            modal:false,
            startConfetti: false
          });
          confirmAlert({
              variables: tenantVariables,
              title: errorInputTitle,
              message: stringConstants.NOTANEMAILERROR,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              onConfirm: () => {
                this.setState({
                  modal:true
                })
              }
          });
          return;
        }
        this.setState({
          modal: false,
          startConfetti: false,
          sendPrizeInfoModal: true
        })
      }

    sendWinningEmailAddress(skipLoading){
        let email = this.state.value;
        const tenantVariables = this.props.tenantVariables || {};
        const stringConstants = this.props.stringConstants || {};
        this.setState({
            loading: !skipLoading,
            sendPrizeInfoModal: false,
        });
        const domainRoot = this.Auth.domain;
        if(this.props.isMlbTeam){
            email = this.Auth.getProfile().sub;
        }
        const winningEmailObject = {'emailAddress': email, 'gameId': this.state.gameId, 'language': this.props.language};
        const vm = this;
        this.Auth.fetch(domainRoot + 'services/app/Game/UserSubmitWinningEmail', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
             },
            body: JSON.stringify(winningEmailObject),
        }).then(res => {
            vm.setState({
              loading:false,
            });
            console.log(res)
            if(res.apiStatus === "email_already_submitted" && !skipLoading){
              confirmAlert({
                variables: tenantVariables,
                title: stringConstants.EMAILALREADYENTEREDTEXT,
                message: stringConstants.THISEMAILHASALREADYBEENENTERED,
                confirmLabel: '',
                cancelLabel: stringConstants.OKTEXT,
                onCancel: () => {
                  vm.setState({
                   sendPrizeInfoModal: true,
                   loading: false
                  })
                }
              })
              return;
            }
            if(!skipLoading) {
                vm.setState({
                    sendPrizeInfoModal:false,
                    startConfetti: false,
                    loading:false,
                    scratchState:2
                });
            }

            let confirmationMessage = stringConstants.CHECKYOURINBOXFORPRIZE;
            const teamLocation = this.props.teamLocation;
            let emailSentConfirmationTitle = stringConstants.EMAILSENT;
            if(this.props.isMlbTeam){
                emailSentConfirmationTitle = tenantVariables.winningHeader || "You Won!"
                confirmationMessage = tenantVariables.winningMessage || "Check your MLB app inbox for your prize and thanks for playing!"
            }
            if(teamLocation.indexOf("clevelandbrowns") !== -1){
              confirmationMessage = "Check your inbox for your prize info (check your spam) and thanks for playing. Don’t forget to come back and play every day!"
            }
            confirmAlert({
                title: emailSentConfirmationTitle,
                message: confirmationMessage,
                confirmLabel: '',
                cancelLabel: '👍',
                variables: tenantVariables,
                messageImage: tenantVariables.winningMessageImage,
                onCancel: () => {
                    vm.setState({
                        sendPrizeInfoModal:false,
                        startConfetti: false,
                        loading:false,
                        scratchState:2
                    })
                }
            })
        }).catch(err =>{
            vm.setState({
              loading:false,
            });
            confirmAlert({
                title: stringConstants.WHOOPSTEXT,
                message: stringConstants.SOMETHINGWENTWRONGTEXT,
                confirmLabel: '',
                cancelLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onCancel: () => {
                    vm.setState({
                      modal: true
                    })
                }
            })
        })
    }

    disablePullToRefresh(){
      let prevent = false;
      const element = '#root';
      document.querySelector(element).addEventListener('touchstart', function(e){
        if (e.touches.length !== 1) { return; }

        const scrollY = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
        prevent = (scrollY === 0);
      });

      document.querySelector(element).addEventListener('touchmove', function(e){
        if (prevent) {
          prevent = false;
          e.preventDefault();
        }
      });
    }

    renderStateOne(primaryColor, secondaryColor){
      const { isVisible } = this.state;
      const currentScratcherLocation = this.state.currentScratcherLocation;
      const tenantVariables = this.props.tenantVariables || {};
      const stringConstants = this.props.stringConstants || {};
      document.body.classList.add("lock-background");
      this.disablePullToRefresh();
      const scratchTopImage = tenantVariables.scratcherImage || scratchMaskImage;
      const nextTextVariable = stringConstants.NEXTTEXT;
      const settings = {
            width: 280,
            height: 380,
            image: scratchTopImage,
            finishPercent: 75,
            onComplete: () => this.showNextArrow()
        };
      return(
        <div className="scratch-container-home">
          {
            this.state.scratcherList.map(function(item,index){
              return(
                <div className="scratch-image-container" key={index}>
                    { index === currentScratcherLocation &&
                        <PoseGroup>
                            {isVisible && [
                                <ScratchContainer key={index} pose={isVisible ? 'visible' : 'hidden'} className="scratch-image" id="scratch-image">
                                    <ScratchCard accessibilityClicked={this.state['accessibilityClicked' + index]} {...settings}>
                                        <div className="cover-image-container">
                                            <img src={item.rewardTemplate.pictureUnderScratcher} onLoad={this.handleImageLoaded.bind(this)} onError={this.handleImageErrored.bind(this)} alt={item.isWinning && this.state.hideRevealButton ? item.rewardTemplate.rewardName:""} role={item.isWinning && this.state.hideRevealButton && "alert"} className="cover-image-control"/>
                                        </div>
                                    </ScratchCard>
                                    {tenantVariables.accessibilityOn && !this.state.hideRevealButton &&
                                    <div style={{textAlign: "center", marginTop: 10}}>
                                        <button className="btn btn-scratcher" style={{backgroundColor: primaryColor, color: secondaryColor}} onClick={()=> this.setState({['accessibilityClicked' + index]: true}) }>Can't Scratch? Click Here</button>
                                    </div>
                                    }
                                </ScratchContainer>
                            ]}
                        </PoseGroup>
                    }
                </div>
              )
            }, this)
          }
          <div className="button-item-container">
            <button className="btn btn-scratcher" style={{display: this.state.showNext ? 'block' : 'none', backgroundColor: primaryColor, color: secondaryColor }} onClick={this.moveUpArrayLength.bind(this, currentScratcherLocation)}>{nextTextVariable}</button>
          </div>
        </div>
      )
    }

    renderStateTwo(primaryColor, secondaryColor, teamLocation){
      const tenantVariables = this.props.tenantVariables || {};
      window.history.pushState("",document.title,window.location.pathname);
      const stringConstants = this.props.stringConstants || {};
      const thank_you_header = tenantVariables.thankYouMessage || "Thanks for playing!";
      const thank_you_message = tenantVariables.underThankYouMessage || "";
      const homeTextVariable = stringConstants.HOMETEXT;
      document.body.classList.remove("lock-background");
      return(
        <div className="flex-content-container-home">
          <div className="thanks-container-home">
            <div className="hero-text-container" style={{backgroundColor: "white", padding:20, borderRadius:20, boxShadow: "3px 3px 7px rgba(0,0,0,0.3)"}}>
              <div className="thankYouContainer">
                <h1 className="thankYouHeader" style={{color: "black"}}>{thank_you_header}</h1>
                <span className="thankYouBodyContent" style={{color: "black"}} dangerouslySetInnerHTML={{ __html: thank_you_message}}/>
                <div className="button-item-container">
                  <button className="btn btn-scratcher" style={{backgroundColor: primaryColor, color: secondaryColor}} onClick={()=> window.location.reload(true)}>{homeTextVariable}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    renderStateZero(primaryColor, secondaryColor) {
      const tenantVariables = this.props.tenantVariables || {};
      const stringConstants = this.props.stringConstants || {};
      const mainImage = tenantVariables.frontLogoImage || "";
      document.body.classList.remove("lock-background");
      const letsPlayButtonText = stringConstants.LETSPLAYTEXT;
      return (
        <div className="flex-content-container-home">
          <Box pose={this.state.isVisible ? 'visible' : 'hidden'} className="intro-container-home" >
            <div className="hero-text-container">
              <img src={mainImage} onLoad={this.handleImageLoaded.bind(this)} onError={this.handleImageErrored.bind(this)} className="main-hero-image" alt={tenantVariables.pageTitle || ""}/>
            </div>
            <div className="button-item-container">
              <button className="btn btn-scratcher" style = {{backgroundColor: primaryColor, color: secondaryColor}}  onClick={() => {this.getScratchers()}} disabled={this.state.letsPlayDisabled}>{letsPlayButtonText}</button>
            </div>
          </Box>
        </div>
      )
    }

    render() {
      const tenantVariables = this.props.tenantVariables || {};
      const link = tenantVariables.rulesAndRegsLink;
      const stringConstants = this.props.stringConstants || {};
      const topLeft = tenantVariables.topLeftImage || "";
      const primaryColor = tenantVariables.primaryColor || '#ff0000';
      const secondaryColor = tenantVariables.secondaryColor || '#000';
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      const teamLocation = this.props.teamLocation;
      const rulesAndRegsText = tenantVariables.rulesAndRegsText || "";
      let buttonLogOutColor = primaryColor;
      let rulesInAppButtonText = "Rules & Regs";
      const supportHeader = tenantVariables.supportHeader || "NEED SUPPORT?";
      const supportText = tenantVariables.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
      if(teamLocation.indexOf("clevelandbrowns") !== -1 || teamLocation.indexOf("columbuscrew") !== -1){
        buttonLogOutColor = "white"
      }
        let isUcHealth = false;
        if(teamLocation.indexOf("uchealth") !== -1) {
            isUcHealth = true;
        }
      let redirectLink = '/' + teamLocation;
      if(!verifiedAge){
        let langauge = "/";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(splitWindowLocation.length > 2){
          langauge += splitWindowLocation[2]
        }
        return (
            <Redirect to={redirectLink + "/age_gate" + langauge} />
        )
      }
      document.body.classList.remove("lock-background");
      let renderMainScreenState = this.renderStateZero(primaryColor, secondaryColor);
      let winningHeader = tenantVariables.winningHeader;
      let winningMessage = tenantVariables.winningMessageText;
      let htmlDetected = false;
      if(/<[a-z][\s\S]*>/i.test(winningMessage)){
        htmlDetected = true
      }
      if(winningHeader === "null" || winningHeader === "undefined" || !winningHeader){
        winningHeader = "🎉 WINNER! 🎉"
      }
      if(winningMessage === "null" || winningMessage === "undefined" || !winningMessage){
        winningMessage = "You've won some awesome prizes. Enter your email below & we'll send them your way!"
      }
      if(this.state.scratchState === 1){
        renderMainScreenState = this.renderStateOne(primaryColor, secondaryColor)
      } else if(this.state.scratchState === 2){
        renderMainScreenState = this.renderStateTwo(primaryColor, secondaryColor, teamLocation)
      }
      const cardToShow = this.state.currentScratcherLocation;
      let rewardWon;
      let winningCode;
      let winningPrizeImage;
      let description;
      if(this.state.scratcherList.length > 0){
          if(this.state.scratcherList[cardToShow] && this.state.scratcherList[cardToShow].isWinning){
              rewardWon = this.state.scratcherList[cardToShow];
              winningCode = rewardWon.redeemCode;
              winningPrizeImage = rewardWon.rewardTemplate.emailImage;
              description = rewardWon.rewardTemplate.description;
          }
      }
        let authRequired = tenantVariables.authRequired;
        if(authRequired === "false" || authRequired === "null"){
            authRequired = false;
        }
        return(
          <div className="flex-container-home">
              {this.state.startConfetti &&
                <Confetti numberOfPieces={400}/>
              }
            <div className="loader" style={{display: this.state.loading ? 'block' : 'none' }}>
                <PulseLoader color={primaryColor}/>
              {/*<hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/>*/}
            </div>
            <Box pose={this.state.isVisible ? 'visible' : 'hidden'} className="flex-header-home">
                {this.props.isMlbTeam ?
                    <div style={{width: "25%", height: 38, color: buttonLogOutColor, border: "solid 1px " + buttonLogOutColor, borderRadius: 5, flexGrow:1, maxWidth: "25%", fontFamily: "Oswald"}}>
                        <button className="btn" style={{color: buttonLogOutColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { this.toggleSupport() }}>
                            <Textfit mode="single" max={16}>
                                Support
                            </Textfit>
                        </button>
                    </div>
                    :
                    <div className="header-left">
                      <img src={topLeft} className="topLeftLogo" alt={tenantVariables.tenantName || ""} style={{maxWidth: isUcHealth && 140}}/>
                    </div>
                }
                <div id="specialTextThatNeedsToShowUpOnAllScreens" style={{color: primaryColor, textAlign: 'center', paddingTop:10, fontSize: 12, display: teamLocation.indexOf("kbtimbers") !== -1 ? "block":"none"}}>
                    ©2020 KeyCorp. KeyBank is Member FDIC.
                </div>
                {this.props.isMlbTeam ?
                    <>
                        <div style={{ width: "25%", color: buttonLogOutColor, height: 38, border: "solid 1px " + buttonLogOutColor, borderRadius: 5, flexGrow:1, maxWidth: "25%", fontFamily: "Oswald"}}>
                            <button className="btn" style={{color: buttonLogOutColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => {window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank")}}>
                                <Textfit mode="single" max={16}>
                                    MLB TOU
                                </Textfit>
                            </button>
                        </div>
                        <div style={{ width: "25%", height: 38, color: buttonLogOutColor, border: "solid 1px " + buttonLogOutColor, borderRadius: 5, flexGrow:1, maxWidth: "25%"}}>
                            <button className="btn" style={{color: buttonLogOutColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { window.open("https://www.mlb.com/app/ballpark/official-information/privacy-policy", "_blank") }}>
                                <Textfit mode="single" max={16}>
                                    MLB Privacy Policy
                                </Textfit>
                            </button>
                        </div>
                        <div style={{width: "25%", height: 38, color: buttonLogOutColor, border: "solid 1px " + buttonLogOutColor, borderRadius: 5, flexGrow:1, maxWidth: "25%"}}>
                            <button className="btn" style={{color: buttonLogOutColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { rulesAndRegsText?this.toggleInfoAndRules():window.open(link, '_blank') }}>
                                <Textfit mode="single" max={16}>
                                    {rulesInAppButtonText}
                                </Textfit>
                            </button>
                        </div>
                    </>
                    :
                    <div className="header-right" style={{visibility: authRequired? 'hidden':''}}>
                        <button className="btn btn-logout" onClick={() => { this.Auth.logout() }} style={{color: buttonLogOutColor, borderColor: buttonLogOutColor, boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.LOGOUTTEXT}</button>
                    </div>
                }
            </Box>
            {renderMainScreenState}
            <Modal isOpen={this.state.modal && !this.props.isMlbTeam} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader toggle={this.toggle} style={{color: secondaryColor}}>{winningHeader}</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form onSubmit={this.handleSubmit} id="create-email-form">
                        <div className="form-group">
                          <label style={{fontSize:'18px', display: htmlDetected ? 'none' : ''}} htmlFor="email">{winningMessage}</label>
                          <label style={{fontSize: '18px', display: htmlDetected ? '' : 'none'}} htmlFor="email" dangerouslySetInnerHTML={{__html: winningMessage}}/>
                          <input id="email" name="email" type="email" className="form-control" style={{textAlign:"center"}} value={this.state.value} onChange={this.handleChange} placeholder="example@email.com" />
                        </div>
                        <button className="btn btn-default btn-admin" id="submitButton" style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.SUBMITBUTTONENTER}</button>
                      </form>
                      <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.DONTWANTPRIZETEXT}</button>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.areYouSureModal} toggle={this.areYouSureToggle} style={{width: '90%'}} id="myModalAreYouSure">
              <ModalHeader toggle={this.areYouSureToggle} style={{color: secondaryColor}}>{stringConstants.AREYOUSURETEXT}</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                        <div className="form-group">
                          <label style={{fontSize:'18px'}}>{stringConstants.ONCEPRIZEGONEGIVEUPTEXT}</label>
                        </div>
                      <button className="btn btn-default btn-admin" onClick={() => { this.areYouSureToggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.GIVEUPPRIZE}</button>
                      <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.state.downloadModal ? this.setState({areYouSureModal:false}) : this.toggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.JUSTKIDDINGTAKEMEBACK}</button>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.sendPrizeInfoModal} toggle={this.sendPrizeInfoToggle} style={{width: '90%'}} id="myModalAreYouSure">
              <ModalHeader toggle={this.sendPrizeInfoToggle} style={{color: secondaryColor}}>{stringConstants.SENDPRIZEINFOTEXT}</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                        <div className="form-group">
                          <label style={{fontSize:'1.4rem'}}>{this.state.value}</label>
                        </div>
                      <button className="btn btn-default btn-admin" onClick={() => { this.sendWinningEmailAddress() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'10px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.SENDTEXT}</button>
                      <button className="btn btn-default btn-admin" onClick={() => { this.sendPrizeInfoToggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize: "0.9rem", height:'auto', minWidth: "40%", whiteSpace: "normal", lineHeight: "normal",width: "auto", boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.OOPSWRONGEMAIL}</button>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.downloadModal} id="myModal">
                <center id="downloadModalId">
                    {/*<img style={{padding:10, margin:"auto"}} width={300} height={300} src={winningPrizeImage} alt=""/>*/}
                    {/*<p>{description}</p>*/}
                    {/*<p style={{display: winningCode ? "" : "none"}}>{winningCode}</p>*/}
                    {/*<div style={{marginTop:5}}>*/}
                    {/*    {!this.state.downloadingImage ?*/}
                    {/*        <button type="button" onClick={()=>this.download()} className="btn btn-next" style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>Download</button>*/}
                    {/*        :*/}
                    {/*        <button type="button" className="btn btn-next" style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>*/}
                    {/*            <i className="fa fa-spinner fa-spin" aria-hidden="true"/> Loading*/}
                    {/*        </button>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*<div style={{marginTop:5, marginBottom: 5}}>*/}
                    {/*    <button type="button" className="btn btn-next" onClick={()=> this.setState({areYouSureModal:true})} style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>Dismiss</button>*/}
                    {/*</div>*/}
                </center>
            </Modal>
            <Modal isOpen={this.state.modalInfoAndRules} toggle={this.toggleInfoAndRules} style={{width: '90%'}} id="infoAndRulesModal">
              <ModalHeader toggle={this.toggleInfoAndRules} style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                    {!this.props.isMlbTeam && stringConstants.HOWTOPLAYTEXT}
                </span>
              </ModalHeader>
              <ModalBody>
                  <div className="container-out">
                      <div className="question-box question-form">
                          <p dangerouslySetInnerHTML={{ __html:rulesAndRegsText}}/>
                          {!this.props.isMlbTeam &&
                              <button className="btn btn-default btn-admin" onClick={() => {  window.open(link, '_blank') }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.RULESTEXT}</button>
                          }
                          <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleInfoAndRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.CLOSETEXT}</button>
                      </div>
                  </div>
              </ModalBody>
            </Modal>
              <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
          </div>
     )
    }
}

export default Home;
