import React, {Component} from 'react';
import '../../styles/css/Login.css'
import '../../styles/css/main.css'
import '../../styles/css/Home.css';

class ErrorPage extends Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    componentDidMount(){
    }

    render() {
        return(
          <div className="main-container-login">
            <div className="content-container-login">
              <center style = {{marginTop: "80px"}}>
                <h1 style = {{color: "white"}}>OH NO!</h1>
                <h2 style = {{color: "white"}}>Something went wrong! Check the link and try again!</h2>
              </center>
            </div>
          </div>
     )
    }
}

export default ErrorPage;
