import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import Raven from 'raven-js';

if(process.env.NODE_ENV === "production" && window.location.hostname === 'scratchers.sqwadapp.co'){
  Raven.config(
    "https://b47f4687c9274e3e978678a84d82e6c5@sentry.io/1319355",{
      environment: process.env.NODE_ENV
    }
  ).install();
}

ReactDOM.render(<App />, document.getElementById('root'));

if(process.env.NODE_ENV === "production" && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
            registration.unregister()
        }
    })
}

