import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { confirmAlert } from '../utils/react-confirm-alert';
import AuthService from '../utils/AuthService';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


class AgeVerification extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.Auth = new AuthService();
        const teamLocation = window.location.pathname.split('/')[1] || ""
        this.state = {
            loading: true,
            redirect: false,
            modal: true,
            verifiedAge: false,
            user_age: sessionStorage.getItem('verifiedAge' + teamLocation) || false
        }
    }

    componentDidMount(){
      if (this.Auth.loggedIn()) {
          this.setState({
            redirect: true,
          })
      }
    }

    handleChange (evt) {
      this.setState({ [evt.target.name]: evt.target.value });
    }

    submit_age_verification(age_to_verify){
      const year = this.state.year;
      const month = this.state.month;
      const day = this.state.day;
      const legalAge = parseInt(age_to_verify, 10);
      const teamLocation = this.props.teamLocation;
      const tenantVariables = this.props.tenantVariables || {};
      const stringConstants = this.props.stringConstants || {};
      this.setState({
        modal: false
      })
      if(!year || !month || !day){
        confirmAlert({
            title: stringConstants.INPUTERRORTITLE,
            message: stringConstants.INPUTERRORENTERALLINPUTS,
            cancelLabel: "",
            confirmLabel: stringConstants.OKTEXT,
            variables: tenantVariables,
            onConfirm: () => {
              this.setState({
                modal:true
              })
            }
        })
        return;
      }
      var bday = [
        parseInt(year, 10),
        parseInt(month, 10) || 1,
        parseInt(day, 10) || 1
      ].join('/')
      var age = ~~((new Date().getTime() - +new Date(bday)) / (31557600000))

      if (age >= legalAge) {
        try {
          sessionStorage.setItem('birthday' + teamLocation, bday);
          sessionStorage.setItem('verifiedAge' + teamLocation, age);
        } catch(e){
          console.log(e)
          alert(stringConstants.NOTENOUGHSPACEERROR)
        }
      } else {
        confirmAlert({
            title: '',
            message: stringConstants.UNABLETOPROCESSSUBMISSION,
            cancelLabel: "",
            confirmLabel: stringConstants.OKTEXT,
            variables: tenantVariables,
            onConfirm: () => {
              this.setState({
                modal:true
              })
            }
        })
      }
    }

    render() {
        const { redirect } = this.state
        const tenantVariables = this.props.tenantVariables || {};
        const stringConstants = this.props.stringConstants || {};
        let variable_age = tenantVariables.ageToVerify || 21;
        variable_age = parseInt(variable_age, 10);
        const verifiedAge = this.props.checkForAgeGate(tenantVariables);
        const teamLocation = window.location.pathname.split('/')[1] || ""
        const redirectLink = '/' + teamLocation;
        if (verifiedAge) {
            const splitWindowLocation = window.location.pathname.split('/') || ""
            let langauge = "/"
            if(splitWindowLocation.length > 3){
              langauge += splitWindowLocation[3]
            }
            return (
                <Redirect to={redirectLink + langauge} />
            )
        }

        const primaryColor = tenantVariables.primaryColor || '#000'
        const secondaryColor = tenantVariables.secondaryColor || '#000'
        const submitButtonText = stringConstants.SUBMITBUTTONENTER;

        return (
          <div className="main-container-login" style={{backgroundColor:"black"}}>
            <Modal isOpen={this.state.modal} style={{width: '90%'}} id="ageGateModal">
              <ModalHeader style={{color: secondaryColor, marginBottom: '10px'}}>{stringConstants.CONFIRMYOURAGETEXT}</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form id="user-values-form">
                        <div className="input-group">
                          <div className="col-md-4 col-sm-12">
                            <input id="month" name="month" type="number" className="form-control" value={this.state.month} onChange={this.handleChange} placeholder="MM" min='1' max='12'/>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <input id="day" name="day" type="number" className="form-control" value={this.state.day} onChange={this.handleChange} placeholder="DD" min='1' max='31'/>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <input id="year" name="year" type="number" className="form-control" value={this.state.year} onChange={this.handleChange} placeholder="YYYY" min="1900" max="2030"/>
                          </div>
                        </div>
                        <button type="button" className="btn btn-default btn-admin" onClick={() => { this.submit_age_verification(variable_age) }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem'}}>{submitButtonText}</button>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
          </div>
        )
    }
}

export default AgeVerification
