import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { confirmAlert } from '../utils/react-confirm-alert';
import infoIcon from '../../styles/images/info_icon.png';
import posed from 'react-pose';
import AuthService from '../utils/AuthService';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../../styles/css/Login.css'
import '../../styles/css/main.css'
import PulseLoader from "react-spinners/PulseLoader";
import {getURLParameter, validateEmail} from "../utils/HelpfulFunction";
import {Textfit} from "react-textfit";
const Box = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
});

class Login extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
        this.toggleInfoAndRules = this.toggleInfoAndRules.bind(this);
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.handleImageErrored = this.handleImageErrored.bind(this);
        this.toggleSupport = this.toggleSupport.bind(this);
        this.Auth = new AuthService();
        const openPopUp = getURLParameter('openpopup');
        let shouldModalStartOpen = false;
        if(openPopUp === true || openPopUp === "true"){
            shouldModalStartOpen = true
        }
        this.state = {
            loading: true,
            isVisible: false,
            redirect: false,
            disableText: false,
            name: '',
            email: '',
            zipCode: '',
            modal: shouldModalStartOpen,
            pin_display: false,
            country_code: "1",
            value: "",
            pin: "",
            modalRules: false,
            phoneNumber: ""
        }
    }

    componentDidMount(){
      if (this.Auth.loggedIn()) {
          this.setState({
            redirect: true,
          })
      }
      // else {
      //   const $bg = document.getElementById('mainContent');
      //   //TIMELINE
      //   const plTl = new TimelineMax({
      //     //paused: true,
      //     //delay: 3,
      //       onComplete: () => {
      //         // var element = document.getElementById('mainContent')
      //         // var transfromString = ("translate(0%, 0%)");
      //         // element.style.width = "100%"
      //         // element.style.webkitTransform = transfromString;
      //         // element.style.MozTransform = transfromString;
      //         // element.style.msTransform = transfromString;
      //         // element.style.OTransform = transfromString;
      //         // element.style.transform = transfromString;
      //       }
      //     });
      //
      //   plTl.add('start')
      //   .to($bg, 0, {
      //     x:'0%'
      //   })
      //   // .fromTo($bg, 0.4, {
      //   //   autoAlpha: 0,
      //   // }, {
      //   //   autoAlpha: 1,
      //   //   ease: Power3.easeOut
      //   // }, 'start')
      //
      //   // .fromTo($bg, 1.8, {
      //   //   scale: 2,
      //   //   x: '-70%'
      //   // }, {
      //   //   scale: 1,
      //   //   x: '0%',
      //   //   ease: Power3.easeOut
      //   // }, 'start')
      //
      //   .to($bg, 1, {
      //     x: '-=7%',
      //     ease: Power3.easeOut
      //   }, 'shiftRight')
      //   .add('shiftLeft')
      //   .to($bg, 8, {
      //     x: '-=5%',
      //     ease: Power0.easeNone
      //   }, 'shiftLeft')
      // }
    }

    signInWithNumber(){
      this.setState({loading:true})
      this.props.setTheme().then(async gotTheme => {
        if(gotTheme){
            if(gotTheme.preventLoginIfNoPrizes) {
                const isGameOpen = await this.checkIfGameIsOpenAndHasPrizes();
                if (!isGameOpen) {
                    const stringConstants = this.props.stringConstants;
                    confirmAlert({
                        title: stringConstants.GAMEINACTIVETEXT,
                        message: stringConstants.COMEBACKLATERTEXT,
                        cancelLabel: "",
                        confirmLabel: stringConstants.OKTEXT,
                        variables: this.props.tenantVariables,
                        onConfirm: () => {
                            this.setState({
                                loading: false
                            })
                        }
                    })
                    return;
                }
            }
            this.setState({
                modal: true,
                loading: false
            })
        } else {
            this.setState({
                loading: false
            })
        }
      })
    }

    handleImageLoaded() {
      setTimeout(() => this.setState({
        loading: false,
        isVisible: true
      }), 1);
    }

    handleImageErrored() {
      console.log("IMAGE FAILED TO LOAD")
      this.setState({ loading: false });
    }

    handleChange (evt) {
      this.setState({ [evt.target.name]: evt.target.value });
    }

    tryToSignIn(){
      const tenantVariables = this.props.tenantVariables || {};
      const stringConstants = this.props.stringConstants || {};
      const teamLocation = this.props.teamLocation;
      const confirmAlertTitleInputError = stringConstants.INPUTERRORTITLE;
      let confirmAlertZipCodeInputError = stringConstants.ZIPCODEMUSTBEFILLEDTEXT;
      if(teamLocation.indexOf("njdevils") !== -1){
          confirmAlertZipCodeInputError = "Black & Red Id is required"
      }
      const confirmAlertNotOldEnoughInputError = stringConstants.NOTOLDENOUGHTOPLAYOURGAME;
      const confirmAlertNameInputError = stringConstants.NAMEERRORLOGIN;
      this.setState({
        modalRules:false
      });
      const number = this.state.value;
      const country_code = this.state.country_code;
      const zipCode = this.state.zipCode.trim() || "";
      const email = this.state.email.trim();
      const name = this.state.name.trim();
      let phoneNumber = this.state.phoneNumber.trim();
      phoneNumber = phoneNumber.replace(/\D/g,'');
      const cleanCountryCode = country_code.replace(/\D/g,'');
      const cleanNumber = number.replace(/\D/g,'');
      const tenantId = tenantVariables.id;
      let bday;
      try {
          bday = sessionStorage.getItem("birthday" + teamLocation) || "";
      } catch (e) {
          this.setState({
              modal:false,
          })
          confirmAlert({
              title: stringConstants.ERRORTEXT,
              message: stringConstants.NOCOOKIESTEXT,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              variables: tenantVariables,
              onConfirm: () => {
                  this.setState({
                      modal:true
                  })
              }
          })
          return;
      }
      if(teamLocation.indexOf("clevelandbrowns") !== -1 || teamLocation.indexOf("dallascowboys") !== -1){
        bday = this.state.birthday;
        const age_to_verify = tenantVariables.ageToVerify || 18;
        const legalAge = parseInt(age_to_verify, 10);
        const age = ~~((new Date().getTime() - +new Date(bday)) / (31557600000))
        if (age >= legalAge) {
          console.log("YOU ARE OLD ENOUGH!")
        } else {
          this.setState({
            modal: false,
          })
          confirmAlert({
              title: confirmAlertTitleInputError,
              message: confirmAlertNotOldEnoughInputError,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
              variables: tenantVariables,
              onConfirm: () => {
                this.setState({
                  modal:true
                })
              }
          })
          return;
        }
      }
      let collectPromo = tenantVariables.promotionCollection || false;
      if(collectPromo === "false" || collectPromo === "null" || !collectPromo){
        collectPromo = false
      } else {
        collectPromo = this.refs.agree_to_promotion.checked
      }
        let collectPromoTwo = tenantVariables.promotionCollectionTwo || false;
        if(collectPromoTwo === "false" || collectPromoTwo === "null" || !collectPromoTwo){
            collectPromoTwo = false
        } else {
            collectPromoTwo = this.refs.agree_to_promotion_two.checked
        }
      let collectUserName = tenantVariables.collectName;
      if(collectUserName === "false" || collectUserName === "null"){
        collectUserName = false;
      }
      let collectEmail = tenantVariables.collectEmail;
      if(collectEmail === "false" || collectEmail === "null"){
        collectEmail = false;
      }
      let collectPhoneNumber = tenantVariables.collectPhoneNumber;
      if(collectPhoneNumber === "false" || collectPhoneNumber === "null"){
          collectPhoneNumber = false;
      }
      if(collectPhoneNumber && phoneNumber === "") {
          confirmAlert({
              variables: tenantVariables,
              title: confirmAlertTitleInputError,
              message: stringConstants.INVALIDNUMBERNOAREACODE,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT
          })
          return;
      }
      if(collectPhoneNumber && !/^\d+$/.test(phoneNumber)){
        confirmAlert({
            variables: tenantVariables,
            title: confirmAlertTitleInputError,
            message: stringConstants.INVALIDNUMBERNOAREACODE,
            cancelLabel: "",
            confirmLabel: stringConstants.OKTEXT
        })
        return;
      } else if(collectPhoneNumber && phoneNumber.length !== 10){
        confirmAlert({
            variables: tenantVariables,
            title: confirmAlertTitleInputError,
            message: stringConstants.INVALIDNUMBERNOAREACODE,
            confirmLabel: stringConstants.OKTEXT,
            cancelLabel: ""
        })
        return;
      }
      let collectZipCode = tenantVariables.collectZipCode;
      if(collectZipCode === "false" || collectZipCode === "null"){
        collectZipCode = false;
      }
      let allowedZips = tenantVariables.allowedZips || "";
      allowedZips = allowedZips.trim();
      if(collectZipCode && zipCode.length === 0){
        this.setState({
          modal:false,
        })
        confirmAlert({
            title: confirmAlertTitleInputError,
            message: confirmAlertZipCodeInputError,
            cancelLabel: "",
            confirmLabel: stringConstants.OKTEXT,
            variables: tenantVariables,
            onConfirm: () => {
              this.setState({
                modal:true
              })
            }
        })
        return;
      }
      var vm = this;
      if(collectUserName && (name === "" || name.split(' ').length <= 1 || name.length > 35)){
        this.setState({
          modal:false,
        })
        confirmAlert({
            title: confirmAlertTitleInputError,
            message: confirmAlertNameInputError,
            cancelLabel: "",
            confirmLabel: stringConstants.OKTEXT,
            variables: tenantVariables,
            onConfirm: () => {
              this.setState({
                modal:true
              })
            }
        })
        return;
      }
      this.setState({
        loading: true,
      })
      this.continue_sigining_in(collectEmail, number, cleanCountryCode, cleanNumber, tenantId, collectPromo, collectPromoTwo, zipCode, bday, email, vm, name, stringConstants, phoneNumber)
    }

    anonymousLogin(){
        const tenantVariables = this.props.tenantVariables || {};
        const stringConstants = this.props.stringConstants || {};
        const confirmAlertTitleInputError = stringConstants.INPUTERRORTITLE;
        const tenantId = tenantVariables.id;
        if(!this.refs.agree_to_rules_regs.checked){
            this.setState({
                modal:false,
            })
            confirmAlert({
                title: stringConstants.INPUTERRORTITLE,
                message: stringConstants.PLEASEAGREETORULESANDREGS,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            return;
        }
        this.setState({
            loading: true,
        })
        const vm = this;
        this.Auth.anonymousLogin(tenantId).then(res => {
            this.setState({
                loading: false,
            })
            if(res.authToken){
                vm.Auth.setToken(res.authToken);
                vm.props.checkIfAuthenticated();
            }
        })
        .catch(err => {
                this.setState({
                    loading: false,
                })
                confirmAlert({
                    title: confirmAlertTitleInputError,
                    message: stringConstants.OHNOSOMETHINGWENTWRONG,
                    cancelLabel: "",
                    confirmLabel: stringConstants.OKTEXT,
                    variables: tenantVariables,
                })
            })
    }

    async checkIfGameIsOpenAndHasPrizes(){
        const teamLocation = this.props.teamLocation;
        const currentUrl = 'https://scratchers.sqwadhq.com/' + teamLocation;
        const tenantObject = {'tenantUrl': currentUrl};

        return await this.Auth.fetch(this.Auth.domain + 'services/app/Game/CheckIfExistsAndHasPrizes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tenantObject)
        }).then(res => {
            return res && res.apiStatus === "game_found";
        })
    }

    async handleSubmit(event) {
        event.preventDefault();
        const stringConstants = this.props.stringConstants || {};
        const teamLocation = this.props.teamLocation;
        if(!this.refs.agree_to_rules_regs.checked){
            this.setState({
                modal:false,
            })
            confirmAlert({
                title: stringConstants.INPUTERRORTITLE,
                message: stringConstants.PLEASEAGREETORULESANDREGS,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: this.props.tenantVariables,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            return;
        }
        let isGermanTeam = false;
        if(teamLocation.indexOf("wolfsburgvfl") !== -1){
          isGermanTeam = true
        }
        if(isGermanTeam){
          this.setState({
            modalRules:true,
            modal:false
          })
        } else {
          this.tryToSignIn()
        }
      }

    phoneNumberCheck(number, cleanCountryCode, cleanNumber){
        const stringConstants = this.props.stringConstants || {};
        const tenantVariables = this.props.tenantVariables || {};
        const confirmAlertNumberInputError = stringConstants.NUMBERANDCOUNTRYBLANK;
        const confirmAlertInvalidNumberInputError = stringConstants.INVALIDNUMBERNOAREACODE;
        const confirmAlertTooManyNumberInputError = stringConstants.INVALIDNUMBERTOMANYNUMBERS;
        const confirmAlertCountryCodeInputError = stringConstants.INVALIDCOUNTRYCODE;
        const confirmAlertTitleInputError = stringConstants.INPUTERRORTITLE;
        let errorFound = false;
        if(!number || !cleanCountryCode){
            this.setState({
                modal:false,
                loading: false
            })
            confirmAlert({
                title: confirmAlertTitleInputError,
                message: confirmAlertNumberInputError,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            errorFound = true;
        } else if(cleanNumber.length < 10){
            this.setState({
                modal:false,
                loading: false
            })
            confirmAlert({
                title: confirmAlertTitleInputError,
                message: confirmAlertInvalidNumberInputError,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            errorFound = true;
        } else if(cleanNumber.length > 10){
            this.setState({
                modal:false,
                loading: false
            })
            confirmAlert({
                title: confirmAlertTitleInputError,
                message: confirmAlertTooManyNumberInputError,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            errorFound = true;
        } else if(cleanCountryCode.length > 3){
            this.setState({
                modal:false,
                loading: false
            })
            confirmAlert({
                title: confirmAlertTitleInputError,
                message: confirmAlertCountryCodeInputError,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            errorFound = true;
        }
        return errorFound;
    }

    continue_sigining_in(collectEmail, number, cleanCountryCode, cleanNumber, tenantId, collectPromo, collectPromoTwo, zipCode, bday, email, vm, name, stringConstants, phoneNumber){
        var tenantVariables = this.props.tenantVariables || {};
        var somethingWentWrongErrorMessage = stringConstants.OHNOSOMETHINGWENTWRONG;
        var confirmAlertEmailNotBlankInputError = stringConstants.EMAILCANTBEBLANK;
        var confirmAlertNotEmailInputError = stringConstants.NOTANEMAILERROR;
        var confirmAlertTitleInputError = stringConstants.INPUTERRORTITLE;
        var confirmAlertBlockedEmailInputError = stringConstants.INPUTBLOCKEDEMAIL;
        const htmlParameterUserId = getURLParameter("userid");
        let htmlParameterCheckIn = getURLParameter("checkin");
        //MLB CHECKIN CHECK
        if(this.props.isMlbTeam && tenantVariables.collectDistance && htmlParameterCheckIn){
            htmlParameterCheckIn = htmlParameterCheckIn.trim().toLowerCase();
            //necessary because MLB app could pass check-in confirmation as y or yes
            const firstCharacter = htmlParameterCheckIn.charAt(0);
            if(firstCharacter !== "y"){
                this.setState({
                    modal:false,
                    loading: false
                })
                const notAcceptableHeader = tenantVariables.notAcceptableLocationHeader || "Hold On";
                const notAcceptableMessage = tenantVariables.notAcceptableLocationMessage || "Please check in on the MLB app before playing!";
                confirmAlert({
                    title: notAcceptableHeader,
                    message: notAcceptableMessage,
                    cancelLabel: "",
                    confirmLabel: stringConstants.OKTEXT,
                    variables: tenantVariables,
                    onConfirm: () => {
                        this.setState({
                            modal:true
                        })
                    }
                })
                return;
            }
        } else if(this.props.isMlbTeam && tenantVariables.collectDistance && !htmlParameterCheckIn){
            this.setState({
                modal:false,
                loading: false
            })
            const notAcceptableHeader = tenantVariables.notAcceptableLocationHeader || "Hold On";
            const notAcceptableMessage = tenantVariables.notAcceptableLocationMessage || "Please check in on the MLB app before playing!";
            confirmAlert({
                title: notAcceptableHeader,
                message: notAcceptableMessage,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            return;
        }

        if(!collectEmail && !htmlParameterUserId && !this.props.isMlbTeam){
            let errorWithPhoneNumber = this.phoneNumberCheck(number, cleanCountryCode, cleanNumber);
            if(errorWithPhoneNumber){
                return;
            }
          vm.Auth.getAuth(cleanNumber, cleanCountryCode, name, tenantId, collectPromo, collectPromoTwo, zipCode, bday).then(res => {
            if(res.authToken){
              vm.Auth.setToken(res.authToken);
              vm.props.checkIfAuthenticated();
              // vm.setState({
              //   // redirect: true,
              //   loading: false,
              //   // pin_display: false,
              //   modal: false,
              //  })
            }
            this.setState({
              loading: false,
            })
          })
          .catch(err => {
            this.setState({
              loading: false,
            })
            confirmAlert({
              title: confirmAlertTitleInputError,
              message: stringConstants.OHNOSOMETHINGWENTWRONG,
              cancelLabel: "",
              confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
            })
          })
        } else if(htmlParameterUserId) {
            vm.Auth.authPassUserId(tenantId, htmlParameterUserId, name, collectPromo, collectPromoTwo, zipCode, bday, phoneNumber).then(res => {
                if(res.authToken){
                    vm.Auth.setToken(res.authToken);
                    vm.props.checkIfAuthenticated();
                }
                this.setState({
                    loading: false,
                })
            })
            .catch(err => {
                this.setState({
                    loading: false,
                })
                confirmAlert({
                    title: confirmAlertTitleInputError,
                    message: stringConstants.OHNOSOMETHINGWENTWRONG,
                    cancelLabel: "",
                    confirmLabel: stringConstants.OKTEXT,
                    variables: tenantVariables,
                })
            })
        } else if(this.props.isMlbTeam) {
            this.setState({
                modal:false,
                loading: false
            })
            confirmAlert({
                title: "Login Error",
                message: "Something went wrong, please close the MLB app and try again",
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                    this.setState({
                        modal:true
                    })
                }
            })
            return;
        } else {
          if(!email){
            this.setState({
              modal:false,
              loading: false
            })
            confirmAlert({
                title: confirmAlertTitleInputError,
                message: confirmAlertEmailNotBlankInputError,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                  this.setState({
                    modal:true
                  })
                }
            })
            return;
          }
          if(!validateEmail(email)){
            this.setState({
              modal:false,
              loading: false
            })
            confirmAlert({
                title: confirmAlertTitleInputError,
                message: confirmAlertNotEmailInputError,
                cancelLabel: "",
                confirmLabel: stringConstants.OKTEXT,
                variables: tenantVariables,
                onConfirm: () => {
                  this.setState({
                    modal:true
                  })
                }
            })
            return;
          }

          vm.Auth.emailLogin(email, name, tenantId, collectPromo, collectPromoTwo, zipCode, bday, phoneNumber).then(res => {
              this.setState({
                  loading: false,
              })
            if(res.authToken){
              vm.Auth.setToken(res.authToken);
              vm.props.checkIfAuthenticated();
            } else if(res.apiStatus === "user_email_block"){
                this.setState({
                    modal:false
                })
                confirmAlert({
                    title: confirmAlertTitleInputError,
                    message: confirmAlertBlockedEmailInputError,
                    cancelLabel: "",
                    confirmLabel: stringConstants.OKTEXT,
                    variables: tenantVariables,
                    onConfirm: () => {
                        this.setState({
                            modal:true
                        })
                    }
                })
            }
          })
          .catch(err => {
            alert(somethingWentWrongErrorMessage);
            this.setState({
              loading: false,
            })
          })
        }
      setTimeout(() => this.setState({ disableText: false }), 5000);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    toggleInfoAndRules(){
        const teamLocation = this.props.teamLocation;
        if(teamLocation === "baskonia"){
            window.open("https://www.baskonia.com/wp-content/uploads/2020/10/BBLL-BKN-Halloween.pdf")
        } else if(teamLocation === "alaves"){
            window.open("https://files.proyectoclubes.com/alaves/202010/29161642bbll-da-halloween.pdf")
        } else {
            this.setState({
                modalInfoAndRules: !this.state.modalInfoAndRules,
                modal: this.props.isMlbTeam
            });
        }
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport
        });
    }

    toggleRules() {
        this.setState({
            modalRules: !this.state.modalRules
        });
    }

    render() {
        const { redirect } = this.state;
        const tenantVariables = this.props.tenantVariables || {};
        const stringConstants = this.props.stringConstants || {};
        const isMlbTeam = this.props.isMlbTeam;
        const verifiedAge = this.props.checkForAgeGate(tenantVariables);
        const teamLocation = this.props.teamLocation;
        const redirectLink = '/' + teamLocation;
        let isCleveland = false;
        if(teamLocation.indexOf("clevelandbrowns") !== -1 || teamLocation.indexOf("columbuscrew") !== -1){
          isCleveland = true
        }
        let isSolarBears = false;
        if(teamLocation.indexOf("orlandosolarbears") !== -1 || teamLocation.indexOf("sbtest") !== -1 || teamLocation.indexOf("testaccount") !== -1){
            isSolarBears = true
        }
        let isDallas = false;
        if(teamLocation.indexOf("dallascowboys") !== -1){
            isDallas = true
        }
        let isUcHealth = false;
        if(teamLocation.indexOf("uchealth") !== -1) {
            isUcHealth = true;
        }
        let isMoa2 = false;
        if(teamLocation === "moa2"){
            isMoa2 = true;
        }
        let isPfhof = false;
        if(teamLocation === "pfhof"){
            isPfhof = true;
        }
        let collectPromoText = tenantVariables.promotionCollection;
        if(collectPromoText === "false" || collectPromoText === "null"){
          collectPromoText = false;
        }
        let collectPromoTextTwo = tenantVariables.promotionCollectionTwo;
        if(collectPromoTextTwo === "false" || collectPromoTextTwo === "null"){
            collectPromoTextTwo = false;
        }
        let collectOptInNotDefaultCheck = tenantVariables.collectOptInNotDefaultCheck;
        if(collectOptInNotDefaultCheck === "false" || collectOptInNotDefaultCheck === "null"){
            collectOptInNotDefaultCheck = false;
        }
        let collectOptInTwoNotDefaultCheck = tenantVariables.collectOptInTwoNotDefaultCheck;
        if(collectOptInTwoNotDefaultCheck === "false" || collectOptInTwoNotDefaultCheck === "null"){
            collectOptInTwoNotDefaultCheck = false;
        }
        let collectUserName = tenantVariables.collectName
        if(collectUserName === "false" || collectUserName === "null"){
          collectUserName = false;
        }
        let collectEmail = tenantVariables.collectEmail
        if(collectEmail === "false" || collectEmail === "null"){
          collectEmail = false;
        }
        let authRequired = tenantVariables.authRequired;
        if(authRequired === "false" || authRequired === "null"){
            authRequired = false;
        }
        let anonymousLogin = tenantVariables.anonymousLogin;
        if(anonymousLogin === "false" || anonymousLogin === "null"){
            anonymousLogin = false;
        }
        let collectZipCode = tenantVariables.collectZipCode;
        if(collectZipCode === "false" || collectZipCode === "null"){
          collectZipCode = false;
        }
        let collectPhoneNumber = tenantVariables.collectPhoneNumber;
        if(collectPhoneNumber === "false" || collectPhoneNumber === "null"){
            collectPhoneNumber = false;
        }

        let buttonText = stringConstants.SUBMITTEXT;
        if(this.state.pin.length > 0){
          buttonText = stringConstants.VERIFYTEXT
        }
        let anonymousButtonText = "Let's Play"
        let language = "/";
        const splitWindowLocation = window.location.pathname.split('/') || "";
        if(splitWindowLocation.length > 3){
          language += splitWindowLocation[3]
        }
        if(!verifiedAge){
          return (
              <Redirect to={redirectLink + "/age_gate" + language} />
          )
        }

        if (redirect || this.Auth.loggedIn()) {
            return (
                <Redirect to={redirectLink + language} />
            )
        }
        const htmlParameterUserId = getURLParameter("userid");
        let userIdPassed = false;
        if(htmlParameterUserId || this.props.isMlbTeam){
            userIdPassed = true;
        }
        const phoneNumberButton = stringConstants.GETSTARTEDTEXT;
        let letsStartText = stringConstants.LETSSTARTTEXT;
        if(isUcHealth) {
            letsStartText = "You're on Deck";
        }
        const getInfoToStart = stringConstants.WEJUSTNEEDTOGETYOUTEXT;
        const agreeWord = stringConstants.IAGREETOYOUTEXT
        const rulesAndRegs = stringConstants.RULESANDREGSTEXT
        const cancelText = stringConstants.CANCELTEXT
        const pinNumberText = stringConstants.PINNUMBERTEXTEDTOYOUTEXT
        const placeHolderEmailText = stringConstants.PLACEHOLDEREMAILTEXT
        const placeHolderFirstLastText = stringConstants.PLACEHOLDERNAMETEXT
        const placeHolderPhoneText = stringConstants.PLACEHOLDERPHONETEXT
        let placeHolderZipcodeText = stringConstants.PLACEHOLDERZIPCODETEXT;
        if(teamLocation.indexOf("njdevils") !== -1){
            placeHolderZipcodeText = "Black & Red Id"
        } else if(isMoa2){
            placeHolderZipcodeText = "Seat #"
        }

        const placeHolderBirthdayText = stringConstants.PLACEHOLDERBIRTHDAYTEXT
        const primaryColor = tenantVariables.primaryColor || '#000'
        const secondaryColor = tenantVariables.secondaryColor || '#000';
        const front_logo = tenantVariables.frontLogoImage || "";
        const topLogo = tenantVariables.topLeftImage || "";
        const link = tenantVariables.rulesAndRegsLink;
        let buttonLogOutColor = primaryColor;
        const rulesAndRegsText = tenantVariables.rulesAndRegsText || "";
        const zipCode = this.state.zipCode;
        const agreeToPromotionText = tenantVariables.promoText || "Agree to receive communications from us";
        const agreeToPromotionTextTwo = tenantVariables.promoTextTwo || "Agree to receive communications from us";
        const supportHeader = tenantVariables.supportHeader || "NEED SUPPORT?";
        const supportText = tenantVariables.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
        let labelElement =
            <label className="agree_to_rules_class" htmlFor="group1">
                <input id="group1" type="checkbox" ref="agree_to_rules_regs" /> {agreeWord}
                <span onClick={() =>this.props.isMlbTeam? (this.toggle(), this.toggleInfoAndRules()) : window.open(link, "_blank")} rel="noopener noreferrer"> <u aria-label="Rules & Regs"> {rulesAndRegs}</u></span>
                <span style={{display: teamLocation === "ucsd"? "":"none"}}>and to receiving information from UC San Diego Athletics.</span>
            </label>;
        if(isCleveland){
            labelElement = <label className="agree_to_rules_class"><input type="checkbox" ref="agree_to_rules_regs"/> I have read and agree to the <a target="_blank" rel="noopener noreferrer" href={link}>Official Rules</a> (including the <a target="_blank" rel="noopener noreferrer" href="https://www.clevelandbrowns.com/about/privacy-policy">Privacy Policy</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.clevelandbrowns.com/about/terms-of-use">Terms of Use</a>) and am 18 years or older.</label>
        } else if(isPfhof){
            labelElement = <label className="agree_to_rules_class"><input type="checkbox" ref="agree_to_rules_regs"/> I have read and agree to the <a target="_blank" rel="noopener noreferrer" href={link}>Official Rules</a> and am 18 years or older.</label>
        }
        // else if(isSolarBears || teamLocation.indexOf("testaccount") !== -1){
        //     labelElement = <label className="agree_to_rules_class"><input type="checkbox" ref="agree_to_rules_regs"/> I agree to the <a target="_blank" rel="noopener noreferrer" href={link}>rules and regulations</a>. Text messages are sent by an automated system. Consent is not a condition of purchase. Message and data rates may apply.</label>;
        // }

        return (
          <div className="main-container-login">
            <div className="loader" style={{display: this.state.loading ? 'block' : 'none' }}>
                <PulseLoader color={primaryColor}/>
              {/*<hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/><hr style={{background: primaryColor}}/>*/}
            </div>
            <Box pose={this.state.isVisible ? 'visible' : 'hidden'} className="content-container-login">
                <div className="flex-header-home">
                    {this.props.isMlbTeam ?
                        <div style={{height: 38, color: buttonLogOutColor, border: "solid 1px " + buttonLogOutColor, borderRadius: 5, maxWidth: "25%", fontFamily: "Oswald"}}>
                            <button className="btn" style={{color: buttonLogOutColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { this.toggleSupport() }}>
                                <Textfit mode="single" max={16}>
                                    Support
                                </Textfit>
                            </button>
                        </div>
                        :
                        <div className="header-left">
                            <img src={topLogo} className="topLeftLogo" alt={tenantVariables.tenantName || ""} style={{maxWidth: isUcHealth && 140}}/>
                        </div>
                    }
                    <div id="specialTextThatNeedsToShowUpOnAllScreens" style={{color: primaryColor, textAlign: 'center', paddingTop:10, fontSize: 12, display: teamLocation.indexOf("kbtimbers") !== -1 ? "block":"none"}}>
                        ©2020 KeyCorp. KeyBank is Member FDIC.
                    </div>
                    {!this.props.isMlbTeam ?
                        <div className="header-right">
                            <button className="btn-info-icon" onClick={() => {this.toggleInfoAndRules()}}><img src={infoIcon} id="info-icon" alt="Info"/></button>
                        </div>
                    :
                        <>
                            <div style={{fontFamily: "Oswald"}}>
                                <button className="btn btn-logout" onClick={() => { window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank") }} style={{color: buttonLogOutColor, borderColor: buttonLogOutColor, backgroundColor: "transparent"}}>MLB TOU</button>
                            </div>
                            <div style={{fontFamily: "Oswald"}}>
                                <button className="btn btn-logout" onClick={() => { window.open("https://www.mlb.com/app/ballpark/official-information/privacy-policy", '_blank') }} style={{color: buttonLogOutColor, borderColor: buttonLogOutColor, backgroundColor: "transparent"}}>MLB Privacy Policy</button>
                            </div>
                        </>
                    }
                </div>
                <div className="hero-text-container" pose={this.state.isVisible ? 'visible' : 'hidden'}>
                    <img src={front_logo} onLoad={this.handleImageLoaded.bind(this)} onError={this.handleImageErrored.bind(this)} className="main-hero-image" id="front-logo-img" alt="Scratch & Win Front Image"/>
                </div>
            </Box>
            <Box pose={this.state.isVisible ? 'visible' : 'hidden'} className="button-container-login">
                <div className="button-content-container-number button-item-container">
                    <button id="phoneNumberButton" className="pt-button pt-intent-primary number-button" onClick={() => { this.signInWithNumber() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: 'bold'}}>{phoneNumberButton}</button>
                </div>
            </Box>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="signUpModal">
              <ModalHeader toggle={this.toggle} style={{color: secondaryColor}}>{letsStartText}</ModalHeader>
              <center style={{marginTop:"10px", marginBottom:"10px", display: authRequired || isMlbTeam?"none":""}}>{getInfoToStart}</center>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form onSubmit={this.handleSubmit} id="user-values-form">
                        <div className="input-group" style={{marginBottom:'20px', display: collectEmail && !authRequired && !userIdPassed ? '' : 'none'}}>
                          <input id="email" name="email" type="email" className="form-control" style={{textAlign:"center"}} value={this.state.email} onChange={this.handleChange} placeholder={placeHolderEmailText} />
                        </div>
                        <div className="input-group" style={{marginBottom:'20px', display: collectEmail || authRequired || userIdPassed ? 'none' : ''}}>
                          <input style={{width:"13%", flex:"inherit"}} type="string" id="country_code" className="form-control" name="country_code" value={this.state.country_code} onChange={this.handleChange} />
                          <input id="value" name="value" type="tel" className="form-control" style={{textAlign:"center"}} value={this.state.value} onChange={this.handleChange} placeholder={placeHolderPhoneText} />
                        </div>
                        <div className="input-group" style={{marginBottom:'20px', display: collectUserName ? '' : 'none'}}>
                          <input id="name" name="name" type="string" className="form-control" style={{textAlign:"center"}} value={this.state.name} onChange={this.handleChange} placeholder={placeHolderFirstLastText} />
                        </div>
                        <div className="input-group" style={{marginBottom:'20px', display: collectZipCode ? '' : 'none'}}>
                          <input id="zipCode" name="zipCode" type="string" className="form-control" style={{textAlign:"center"}} value={zipCode} onChange={this.handleChange} placeholder={placeHolderZipcodeText} />
                        </div>
                        <center style={{color:"#495057", display: isCleveland || isPfhof || isDallas ? '' : 'none'}}>Birthday</center>
                        <div className="input-group" style={{marginBottom:'20px', display: isCleveland || isPfhof || isDallas ? '' : 'none'}}>
                          <input id="birthday" name="birthday" type="date" className="form-control" style={{textAlign:"center"}} value={this.state.birthday} onChange={this.handleChange} placeholder={placeHolderBirthdayText} />
                        </div>
                        <div className="input-group" style={{marginBottom:'20px', display: collectPhoneNumber ? '' : 'none'}}>
                            <input id="phoneNumber" name="phoneNumber" type="text" className="form-control" style={{textAlign:"center"}} value={this.state.phoneNumber} onChange={this.handleChange} placeholder={placeHolderPhoneText} />
                        </div>

                        <div className="checkbox" style={{padding:'5px'}}>
                            {labelElement}
                        </div>
                        <div className="checkbox" style={{padding:'5px', display: collectPromoText ? '' : 'none'}}>
                          <label className="agree_to_promotion_class">
                              <input type="checkbox" defaultChecked={!isSolarBears && !collectOptInNotDefaultCheck} ref="agree_to_promotion"/> {agreeToPromotionText}
                          </label>
                        </div>
                        <div className="checkbox" style={{padding:'5px', display: collectPromoText && collectPromoTextTwo ? '' : 'none'}}>
                            <label className="agree_to_promotion_class">
                                <input type="checkbox" defaultChecked={!collectOptInTwoNotDefaultCheck} ref="agree_to_promotion_two"/> {agreeToPromotionTextTwo}
                            </label>
                        </div>

                      {/*<div style={{display:collectPromoText?'table-row':"none",textAlign:'left'}}>*/}
                      {/*    <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>*/}
                      {/*        <input type="checkbox" ref="agree_to_promotion" defaultChecked={isSolarBears ? !!collectPromoText : false} style={{width:20,height:20}}/>*/}
                      {/*    </div>*/}
                      {/*    <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>*/}
                      {/*        <label htmlFor="agreeToPromotionId">{agreeToPromotionText}</label>*/}
                      {/*    </div>*/}
                      {/*</div>*/}

                        <div className="form-group" id="pin_part_of_form" style={{display:"none"}}>
                          <label htmlFor="pin">{pinNumberText}</label>
                          <input id="pin" name="pin" type="string" className="form-control" value={this.state.pin} onChange={this.handlePinChange} placeholder="5555"/>
                        </div>
                        <span style={{display: authRequired && !userIdPassed?"none":""}}>
                            <button type='submit' className="btn btn-default btn-admin" id="submitButton" style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}} disabled={this.state.disableText}>{buttonText}</button>
                        </span>
                        <span style={{display: anonymousLogin && !userIdPassed ? "":"none"}}>
                            <button onClick={()=>this.anonymousLogin()} type="button"  className="btn btn-default btn-admin" id="anonymousButton" style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}} disabled={this.state.disableText}>{anonymousButtonText}</button>
                        </span>
                      </form>
                      <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{cancelText}</button>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalRules} toggle={this.toggleRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader toggle={this.toggleRules} style={{color: secondaryColor, padding:10}}><span style={{fontSize:25}}>{stringConstants.RULESANDREGSTEXT}</span></ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <p style={{width:"100%",height:200, overflow:"scroll",padding:10, border:"1px solid black", fontWeight:100, fontSize:14}}>
                        Zur Nutzung dieses Dienstes ist eine Übermittlung von personenbezogenen Daten an den Dienstanbieter SQWAD Sports Inc. in die USA erforderlich. Wir übermitteln deine personenbezogenen Daten in die USA aufgrund von Art. 49 Abs. 1 b) DSGVO zur Bereitstellung des Dienstes und der Abwicklung des Gewinnspiels.
                        <br/>
                        <br/>
                        Bei den USA handelt es sich um ein sog. Drittland im Sinne der Datenschutz-Grundverordnung, in dem die Regelungen der Datenschutz-Grundverordnung keine unmittelbare Anwendung finden und für das derzeit kein Angemessenheitsbeschluss der Kommission vorliegt. Es kann grundsätzlich ein geringeres Schutzniveau für Deine personenbezogenen Daten bestehen.
                      </p>
                      <button className="btn btn-default btn-admin" onClick={() => { this.tryToSignIn() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{agreeWord}</button>
                      <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); this.toggle() }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{cancelText}</button>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalInfoAndRules} toggle={this.toggleInfoAndRules} style={{width: '90%'}} id="infoAndRulesModal">
                <ModalHeader toggle={this.toggleInfoAndRules} style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {!this.props.isMlbTeam && stringConstants.HOWTOPLAYTEXT}
                    </span>
                </ModalHeader>
                <ModalBody>
                  <div className="container-out">
                      <div className="question-box question-form">
                          <p dangerouslySetInnerHTML={{ __html:rulesAndRegsText}}/>
                          {!this.props.isMlbTeam &&
                            <button className="btn btn-default btn-admin" onClick={() => {  window.open(link, '_blank') }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.RULESTEXT}</button>
                          }
                          <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleInfoAndRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem', boxShadow: tenantVariables.accessibilityOn && "none"}}>{stringConstants.CLOSETEXT}</button>
                      </div>
                  </div>
                </ModalBody>
            </Modal>
              <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
          </div>
        )
    }
}

export default Login
