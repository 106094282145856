import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import ReactSnapPixel from 'react-snapchat-pixel';
import './styles/css/AdminMain.css';
import './styles/css/SideMenu.css';
import Loading from './components/utils/Loading';
import Home from './components/mainapp/Home';
import AgeVerification from './components/mainapp/AgeVerification';
import PrizeViewer from './components/mainapp/PrizeViewer';
import ImageViewer from './components/mainapp/ImageViewer';
import Login from './components/mainapp/Login';
import ErrorPage from './components/utils/ErrorPage';
import AuthService from './components/utils/AuthService';
import {getURLParameter} from "./components/utils/HelpfulFunction";
import { getDistance } from 'geolib';
import GA4React  from "ga-4-react";

let teamLocation = window.location.pathname.split('/')[1] || "";
if(process.env.REACT_APP_ENVIRONMENT === "prod" && teamLocation.indexOf("wolfsburgvfl") === -1 && teamLocation.indexOf("baskonia") === -1 && teamLocation.indexOf("alaves") === -1){
    ReactGA.initialize(process.env.REACT_APP_GA_KEY, {gaOptions: {siteSpeedSampleRate: 100}});
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
    };
    if(teamLocation === "moa"){
      ReactPixel.init('135739357181632', advancedMatching, options);
      ReactSnapPixel.init('05cce333-6708-401d-9b52-0d6e718d3d1b', {}, options);
    }
    const ga4react = new GA4React(process.env.REACT_APP_GA_4_KEY);
    ga4react.initialize().then(
        (ga4) => {
            ga4.pageview(window.location.pathname)
            ga4.gtag('event','pageview',window.location.pathname)
        },
        (err) => {
            console.error(err);
        }
    );
}

function AuthenticatedRoute({component: Component, variablePath, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
              if(authenticated === true){
                return (
                  <Component {...props} {...rest} />
                )
              } else {
                return (
                  <Redirect to={{pathname: variablePath + '/login', state: {from: props.location}}} />
                )
              }
            }} />
    )
}

function logPageView() {
  let teamLocation = window.location.pathname.split('/')[1] || "";
  if(process.env.NODE_ENV === "production" && (window.location.hostname === 'scratchers.sqwadhq.com' || window.location.hostname === 'scratchandwin.sqwadhq.com') && teamLocation.indexOf("wolfsburgvfl") === -1 && teamLocation.indexOf("baskonia") === -1 && teamLocation.indexOf("alaves") === -1){
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname)
      if(teamLocation === "moa"){
          ReactPixel.pageView();
      }
  }
}

class App extends Component {
    constructor(props) {
        super(props);
        this.setTheme = this.setTheme.bind(this);
        this.checkIfAuthenticated = this.checkIfAuthenticated.bind(this);
        const teamLocation = window.location.pathname.split('/')[1] || "";
        if(teamLocation === "tbrowdies"){
            window.open("https://rowdiesscratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "moa2"){
            window.open("https://moascratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "etsu"){
            window.open("https://etsuscratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "ohio"){
            window.open("https://ohiouscratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "fhsu"){
            window.open("https://fhsuscratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "oilers"){
            window.open("https://edmontonoilersscratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "csurams"){
            window.open("https://csuramsscratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "columbusclippers"){
            window.open("https://colclippersscratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "wolfsburgvfl"){
            window.open("https://wolfsburgscratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "gcu"){
            window.open("https://gcuscratch.sqwadhq.com/login", "_self");
        } else if (teamLocation === "vw12"){
            window.open("https://stonybrookscratch.sqwadhq.com/login", "_self");
        }
        this.Auth = new AuthService();
        this.setTheme();
        this.state = {
            admin: false,
            authenticated: false,
            currentUser: null,
            loading: true,
            textLoading: true,
            teamLocation: teamLocation.toLowerCase(),
            tenantVariables: null,
            latitude: null,
            longitude: null
        }
    }

    setTheme(onSuccess, onFail){
      // var vm = this;
      return new Promise((resolve, reject) => {
        const teamLocation = window.location.pathname.split('/')[1] || "";
        const currentUrl = 'https://scratchers.sqwadhq.com/' + teamLocation;
        const tenantObject = {'tenantUrl': currentUrl};
        //set up theme:
        //primary color, secondary color, Top left image, Front Logo image, background image, Scratcher Image, buy tickets link
        this.Auth.fetch(`${this.Auth.domain}services/app/game/GetTenantInfoFromTenantUrl`, {
          method: 'POST',
          headers: {
  	         'Content-Type': 'application/json',
           },
          body: JSON.stringify(tenantObject),
        }).then(res => {
          const tenantVariables = res.entity;
          if(tenantVariables){
            document.title = tenantVariables.pageTitle || "Scratch & Win";
            if(document.getElementById('front-logo-img')){
              document.getElementById('phoneNumberButton').style.color = tenantVariables.secondaryColor;
              document.getElementById('phoneNumberButton').style.backgroundColor = tenantVariables.primaryColor;
              if(tenantVariables.frontLogoImage){
                  document.getElementById('front-logo-img').src = tenantVariables.frontLogoImage;
              }
              let firstElement = document.getElementById('top-left-logo');
              if(tenantVariables.topLeftImage && firstElement){
                  document.getElementById('top-left-logo').src = tenantVariables.topLeftImage;
              }
            }
            document.getElementById('root').style.backgroundImage = "url("+ tenantVariables.backgroundImage.split(' ').join('+')+")";
            this.loadImages(tenantVariables)
          }
          this.setState({
              loading:false
          });
          resolve(tenantVariables)
        })
        .catch(err =>{
          this.setState({
            loading: false,
          });

          resolve(false)
        })
      })
    }

    loadImages(tenantVariables){
      const src = document.getElementById('root').style.backgroundImage;
      const url = src.match(/\((.*?)\)/)[1].replace(/('|")/g,'');
      const vm = this;
      const img = new Image();
      img.onload = function() {
        vm.setState({
          tenantVariables:tenantVariables,
        }, () => {
          console.log("gotVars")
        })
      };
      if(url){
          img.src = url;
      } else {
          this.setState({
              tenantVariables:tenantVariables,
          }, () => {
              console.log("gotVars")
          })
      }
      if (img.complete){
        img.onload();
      } else {
        img.onload();
      }
    }

    getLocation(tenantVariables, stringConstants){
      return new Promise((resolve, reject) => {

        if (!navigator.geolocation) {
          reject(stringConstants.GEOLOCATIONNOTSUPPORTED);
        } else {
          const toCheckLatitude = tenantVariables.latitude || 51.525;
          const toCheckLongitude = tenantVariables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                  resolve({distance: getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                          latitude: toCheckLatitude,
                          longitude: toCheckLongitude,
                      }), lat: toCheckLatitude, long: toCheckLongitude})
              },
              (err) => {
                  if(err.message === "User denied Geolocation"){
                    reject(stringConstants.NEEDBROWSERPERMISSION);
                  } else {
                    reject(stringConstants.UNKNOWNERROROCCURED);
                  }
              }
          );
        }
      })
    }

    checkForAgeGate(tenantVariables) {
      const variable_age_boolean = tenantVariables.ageBlock || false;
      if(!variable_age_boolean){
        return true
      }
      let variable_age = tenantVariables.ageToVerify || 21;
      variable_age = parseInt(variable_age, 10);
      const teamLocation = window.location.pathname.split('/')[1] || "";
      let isPfhof = false;
      if(teamLocation === "pfhof"){
          isPfhof = true;
      }
      let isCleveland = false;
      if(teamLocation.indexOf("clevelandbrowns") !== -1 || teamLocation.indexOf("columbuscrew") !== -1){
        isCleveland = true
      }
      let isDallas = false;
      if(teamLocation.indexOf("dallascowboys") !== -1){
          isDallas = true
      }
      let user_age = sessionStorage.getItem('verifiedAge' + teamLocation) || false;
      if(isNaN(parseInt(user_age))){
        user_age = false
      } else {
        user_age = parseInt(user_age, 10)
      }
      return (user_age && user_age >= variable_age) || (isCleveland) || isDallas || isPfhof;
    }

    checkIfAuthenticated() {
      const loggedIn = this.Auth.loggedIn();
      if(loggedIn){
        let user = this.Auth.getProfile();
        this.setState({
            authenticated: true,
            admin: false,
            currentUser: user,
        })
      } else {
        this.setState({
            authenticated: false,
            admin: false,
            currentUser: null,
        })
      }
    }

    componentDidMount() {
      let parameterLanguage = getURLParameter('language');
      let language = "english";
      const splitWindowLocation = window.location.pathname.split('/') || "";
      if(splitWindowLocation.length > 3){
        language = splitWindowLocation[3]
      } else if(splitWindowLocation.length > 2) {
        language = splitWindowLocation[2]
      }
      if(parameterLanguage){
          language = parameterLanguage
      }
      window.history.pushState("",document.title,window.location.pathname+window.location.search);
      this.loadLanguage(language);
      this.checkIfAuthenticated();
    }

    async loadLanguage(language){
      let languagesConstants = null;
      const teamLocation = window.location.pathname.split('/')[1] || "";
      if(teamLocation==="wolfsburgvfl"){
          languagesConstants = await import("./constants/languages/de.js");
      } else if(teamLocation==="baskonia" || teamLocation==="alaves"){
          languagesConstants = await import("./constants/languages/esp.js");
      } else if(teamLocation==="sportamadok2" || teamLocation==="sportamadok1" || teamLocation === "sportexdemo" || teamLocation === "Decathlondemo"){
          languagesConstants = await import("./constants/languages/hun.js");
      } else if(language){
          try {
              languagesConstants = await import("./constants/languages/"+language+".js");
          } catch(e) {
              languagesConstants = await import("./constants/languages/en.js");
          }
      } else {
          languagesConstants = await import("./constants/languages/en.js");
      }
      this.setState({
        lanaguageConstants: languagesConstants
      }, () => {
          this.setState({
              textLoading: false
          })
      })
    }

  render() {
    let language = "";
    const splitWindowLocation = window.location.pathname.split('/') || "";
    if(splitWindowLocation.length > 3){
      language = splitWindowLocation[3]
    } else if(splitWindowLocation.length > 2) {
      language = splitWindowLocation[2]
    }
    const isMlbTeam = this.state.tenantVariables && this.state.tenantVariables.isMlbTeam;
    if (this.state.loading === true || this.state.textLoading === true) {
        return (
            <Loading loading={true}/>
        )
    }
    return (
      <div style={{ margin: "0 auto" }}>
        <BrowserRouter onUpdate={logPageView()}>
          <div className="main-content">
              <div className="workspace">
                <Switch>
                  <Route
                      exact
                      path="/"
                      render={(props) => {
                        return <ErrorPage {...props} />
                      }}
                  />
                    <Route
                        exact
                        path={`/${this.state.teamLocation}/imageviewer/`}
                        render={(props) => {
                            return <ImageViewer teamLocation={this.state.teamLocation} tenantVariables={this.state.tenantVariables} stringConstants={this.state.lanaguageConstants} {...props} />
                        }}
                    />
                    <Route
                        exact
                        path={`/${this.state.teamLocation}/prizeviewer`}
                        render={(props) => {
                            return <PrizeViewer tenantVariables={this.state.tenantVariables} stringConstants={this.state.lanaguageConstants} teamLocation={this.state.teamLocation} {...props} />
                        }}
                    />
                  <AuthenticatedRoute
                    exact
                    path={`/${this.state.teamLocation}/${language}`}
                    variablePath={`/${this.state.teamLocation}/${language}`}
                    authenticated={this.state.authenticated}
                    stringConstants = {this.state.lanaguageConstants}
                    checkForAgeGate={this.checkForAgeGate}
                    getLocation={this.getLocation}
                    tenantVariables={this.state.tenantVariables}
                    setTheme={this.setTheme}
                    teamLocation={this.state.teamLocation}
                    isMlbTeam={isMlbTeam}
                    component={Home}
                    language={language}
                  />
                  <Route
                    exact
                    path={`/${this.state.teamLocation}/login/${language}`}
                    render={(props) => {
                      return <Login checkIfAuthenticated={this.checkIfAuthenticated} checkForAgeGate={this.checkForAgeGate} getLocation={this.getLocation} setTheme={this.setTheme} tenantVariables={this.state.tenantVariables} stringConstants = {this.state.lanaguageConstants} isMlbTeam={isMlbTeam} teamLocation={this.state.teamLocation} {...props} />
                    }} />
                  <Route
                    exact
                    path={`/${this.state.teamLocation}/age_gate/${language}`}
                    render={(props) => {
                      return <AgeVerification teamLocation={this.state.teamLocation} tenantVariables={this.state.tenantVariables} checkForAgeGate={this.checkForAgeGate} stringConstants={this.state.lanaguageConstants} {...props} />
                    }} />

                  <Redirect to={`/${this.state.teamLocation}/login/${language}`}/>
                </Switch>
              </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
