import React, {Component} from 'react';
import {getURLParameter} from "../utils/HelpfulFunction";
import {QRCodeSVG} from 'qrcode.react';
import AuthService from "../utils/AuthService";
import PulseLoader from "react-spinners/PulseLoader";
import '../../styles/css/PrizeViewer.css';
import { confirmAlert } from '../utils/react-confirm-alert';

let interval;

class PrizeViewer extends Component {
    constructor(props) {
        super(props);
        const currentLocation = window.location.href;
        const currentPath = window.location.pathname;
        const language = getURLParameter('language') ||  "";
        let cleanHost = currentLocation.replace(currentPath, "/" + this.props.teamLocation);
        cleanHost = cleanHost.split("?")[0] + "?language=" + language;
        this.Auth = new AuthService();
        const code = getURLParameter('code');
        const pin = getURLParameter('pin');
        const imageToShow = getURLParameter('image');
        const showQrCode = getURLParameter('qrcode');
        const id = getURLParameter('id');
        const prize = getURLParameter('prize');
        const uid = getURLParameter('uid');
        this.state = {
            imageToShow: imageToShow,
            code: code,
            showQrCode: showQrCode,
            pin: pin,
            id: id,
            isWinner: false,
            loading: true,
            prize: prize,
            requested: false,
            language: language,
            uid: uid,
            redirectUrl: cleanHost
        };
    }

    async componentDidMount() {
        this.setState({
            loading: false
        }, () => {
            const stringConstants = this.props.stringConstants || {};
            const tenantVariables = this.props.tenantVariables || {};
            const teamLocation = this.props.teamLocation;
            const redirectLink = '/' + teamLocation;
            confirmAlert({
                title: stringConstants.WAITTEXT,
                message: stringConstants.CONFIRMINSTOREBODY,
                cancelLabel: stringConstants.CLOSETEXT,
                confirmLabel: stringConstants.OKTEXT,
                onConfirm: () => {
                    this.checkIfPrizeUsed();
                },
                onCancel: () => {
                    window.open(this.state.redirectUrl)
                },
                variables: tenantVariables
            })
        })
        // this.Auth.fetch(this.Auth.domain + 'services/app/Game/checkIfPrizeUsed', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(requestObject),
        // }).then(res => {
        //
        // })
        // const millis = Date.now();
        // const adjustedTimeAsMs = millis + (1000 * 60 * 60 * 24);
        // this.setState({
        //     loading: false,
        //     isWinner: true
        // })
        // interval = setInterval(() => {
        //     const timeLeft = adjustedTimeAsMs - Date.now();
        //     if (timeLeft <= 0) {
        //         clearInterval(interval);
        //         this.setState({
        //             timeLeft: "00:00:00"
        //         })
        //         this.requestGifAndTime();
        //     } else {
        //         let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
        //         let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString();
        //         let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        //         seconds = seconds.toString();
        //         if(seconds.length === 1){
        //             seconds = "0" + seconds;
        //         }
        //         if(hours.length === 1){
        //             hours = "0" + hours;
        //         }
        //         if(minutes.length === 1){
        //             minutes = "0" + minutes;
        //         }
        //         let finalString = hours + ":" + minutes + ":" + seconds;
        //         this.setState({
        //             timeLeft:finalString
        //         })
        //     }
        // }, 100)
    }

    componentWillUnmount() {
        if(interval){
            clearInterval(interval);
        }
    }

    checkIfPrizeUsed(){
        const uid = this.state.uid;
        const requestObject = {'prize': this.state.prize, 'userId': uid};
        this.Auth.fetch(this.Auth.domain + 'services/app/Game/checkIfPrizeUsed', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestObject),
        }).then(res => {
            this.setState({
                loading: false,
                isWinner: res && res.apiStatus === "prize_redeemed",
                requested: true
            })
        }).catch((e) => {
            this.setState({
                loading:false
            })
        })
    }

    render() {
        let {imageToShow, code, showQrCode, pin, isWinner, loading, timeLeft, requested=false} = this.state;
        let gifSource = "https://media.giphy.com/media/o3Vt7LBQZa8pi/giphy.gif";
        let time = "3:32:01"
        const tenantVariables = this.props.tenantVariables || {};
        const primaryColor = tenantVariables.primaryColor || "#000";
        showQrCode = showQrCode === "true";
        if(loading){
            return <PulseLoader color={primaryColor}/>
        }
        let backGroundColor = "red";
        let successText = "SORRY!";
        let textColor = "white";
        if(isWinner){
            backGroundColor = "lightgreen";
            successText = "GOOD TO GO!"
            textColor = "black";
        }
        if(!requested){
            return <div></div>
        }

        return (
            <div style={{textAlign:'center', minHeight:"100vh", backgroundColor: backGroundColor, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                {/*{isWinner &&*/}
                {/*    <div style={{position: "absolute", top: 10, left: 10, minWidth:100}}>*/}
                {/*        <img src={gifSource} alt="" width={50} height={50}/>*/}
                {/*        <div style={{textAlign:"center"}}>*/}
                {/*            {timeLeft}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
                <h1 style={{color: textColor, fontFamily: "Oswald"}}>{successText}</h1>
                {isWinner ?
                    <div style={{width: "10rem", height: "10rem", backgroundColor: "rgba(0, 0, 0, 0.1)", borderRadius: "50%", display: "inline-flex", padding: "1rem", justifyContent: "center", alignItems: "center", fontSize: "5rem"}}>
                        👍
                    </div>
                    :
                    <>
                        <div className="xStyles"/>
                        <div style={{display: "inline-flex", justifyContent: "center", alignItems: "center", color: "white"}}>
                            <span style={{fontSize: "1rem"}}>This offer has already been used</span>
                        </div>
                    </>
                }
                {/*<div style={{width: "100%", maxWidth: 400, marginLeft: "auto", marginRight: "auto", marginTop: "10%", height: "auto"}}>*/}
                {/*    <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} style={{maxWidth: "100%", maxHeight: "100%"}} src={imageToShow} alt=""/>*/}
                {/*</div>*/}
                {/*{showQrCode &&*/}
                {/*    <div style={{marginTop: 20, maxWidth: 400, marginLeft: "auto", marginRight: "auto"}}>*/}
                {/*        <QRCodeSVG value={code} size={"50%"} />*/}
                {/*    </div>*/}
                {/*}*/}
                {/*{code &&*/}
                {/*    <div style={{marginTop: showQrCode?10:20, marginBottom: 20}}>*/}
                {/*        <p style={{color: "black", fontSize: showQrCode?"2vh":"4vh", margin:0}}>{code}</p>*/}
                {/*    </div>*/}
                {/*}*/}
                {/*{pin &&*/}
                {/*    <div style={{marginTop: 20, marginBottom:20}}>*/}
                {/*        <p style={{color: "black", fontSize: "4vh", margin:0}}>{pin}</p>*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
        )
    }
}

export default PrizeViewer;
